import React, { memo } from 'react';
import { apiLocation } from '../../../../../config/env.json';
import { isSignedInSelector, userSelector } from '../../../redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { User } from '../../../redux/prop-types';

const mapStateToProps = (state: Record<string, unknown>) => {
  return createSelector(
    isSignedInSelector,
    userSelector,
    (
      isSignedIn,
      user: User
    ) => ({
      isSignedIn,
      user
    })
  )(state);
};

// (function () {
//   if(location.search == '?messages=info%5B0%5D%3DAccess%2520token%2520is%2520required%2520for%2520this%2520request') {
//     // location.href = apiLocation+"/signin";
//   }
// })();

const Header = (props: any): JSX.Element => {
  const { isSignedIn } = props;
  return (
    <>
      <div id="thetop" />
      {/* <div id="backtotop">
        <a href="#" id="scroll">
          <i className="fal fa-arrow-up" />
          <i className="fal fa-arrow-up" />
        </a>
      </div> */}
      <header id="header_section" className="header_section sticky_header d-flex align-items-center text-white border_bottom clearfix">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <div className="brand_logo">
                <a href="/" className="brand_link">
                  <img src="/assets/images/logo/logo_1.png" alt="logo_not_found" />
                  <img src="/assets/images/logo/logo_2.png" alt="logo_not_found" />
                </a>
                <button type="button" className="menu_btn">
                  <i className="fal fa-bars" />
                </button>
              </div>
            </div>
            <div className="col-lg-8">
              <nav className="main_menu ul_li_center clearfix">
                <ul className="clearfix">
                  <li className="">
                    <a href="/#/">Home</a>
                  </li>
                  <li className="">
                    <a href="/learn/">Learn</a>
                  </li>
                  <li className="menu_item_has_child">
                    <a href="#/">Certification</a>
                    <ul className="submenu">
                      <li><a href="/learn/responsive-web-design/">Responsive Web Design</a></li>
                      <li><a href="/learn/javascript-algorithms-and-data-structures/">Javascript Algorithm &amp; Data Structure</a></li>
                      <li><a href="/learn/front-end-development-libraries/">Frontend Development Libraries</a></li>
                      <li><a href="/learn/data-visualization/">Data Visualization</a></li>
                      <li><a href="/learn/coding-interview-prep/">Coding Interview Preparation</a></li>
                    </ul>
                  </li>
                  <li className="">
                    <a href="/pricing/">Pricing</a>
                  </li>
                  <li className="menu_item_has_child">
                    <a href="#/">Legal</a>
                    <ul className="submenu">
               
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms">Terms of Services</a></li>
            
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
            {!isSignedIn ? <div className="col-lg-2">
              <a href={apiLocation + "/signin"} className="btn btn_border border_white float-right">Sign In</a>
            </div> : <div className="col-lg-2">
              <a href={apiLocation + "/signout"} className="btn btn_border border_white float-right">Logout</a>
            </div>}
          </div>
        </div>
      </header>
      <div className="sidebar-menu-wrapper">
        <div id="mobile_menu" className="mobile_menu">
          <div className="brand_logo mb-50 clearfix">
            <a href="/" className="brand_link">
              <img src="/assets/images/logo/logo_2.png" alt="logo_not_found" />
            </a>
            <span className="close_btn"><i className="fal fa-times" /></span>
          </div>
          <div className="mobile_menu_dropdown menu_list ul_li_block mp_balancing mb-50 clearfix">
            <ul className="clearfix">
            </ul>
          </div>
          <a href={apiLocation + "/signin"} className="btn bg_default_blue">Sign In</a>
        </div>
        <div className="overlay" />
      </div>
    </>
  );
};

Header.displayName = 'Header';


export default connect(
  mapStateToProps
)(withTranslation()(memo(Header)));
// export default Header;
