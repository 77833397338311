import React from 'react';

const Footer = (): JSX.Element => {
  return (
    <>
      <footer id="footer_section" className="footer_section bg_dark_blue text-white deco_wrap pb-0 clearfix">
        <div className="widget_area">
          <div className="container position-relative">
            <div className="row justify-content-lg-between">
              <div className="col-lg-3 col-md-4 col-sm-4">
                <div className="widget useful_links about_content pr-0">
                  <div className="brand_logo mb-30">
                    <a href="/" className="brand_link">
                      <img src="/assets/images/logo/logo_1.png" alt="logo_not_found" />
                    </a>
                  </div>
                  <div className="copyright_text mb-30">
                    <p className="mb-0">
                      Copyright © 2020 <br />Platform by <a href="https://olecons.com/" target={'_blank'} className="author_link">Olecons</a>
                    </p>
                  </div>
                  <div className="social_icon_rounded ul_li ml-30">
                
                    <ul className="clearfix">
                      <li>
                        <a href="https://www.facebook.com/olecons/">
                          <i className="icon-facebook" />
                          <i className="icon-facebook" />
                        </a>
                      </li>
                      {/* <li>
                        <a href="#!">
                          <i className="icon-twitter" />
                          <i className="icon-twitter" />
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="#!">
                          <i className="icon-vimeo" />
                          <i className="icon-vimeo" />
                        </a>
                      </li> */}
                      <li>
                        <a href="https://in.linkedin.com/company/olecons">
                          <i className="icon-linkedin" />
                          <i className="icon-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="widget useful_links ul_li_block">
                  <h3 className="widget_title mb-50">LEARN</h3>
                  <ul className="clearfix">
                    <li><a href="/learn/responsive-web-design/">Responsive Web Design</a></li>
                    <li><a href="/learn/javascript-algorithms-and-data-structures/">Javascript Algorithm &amp; Data Structure</a></li>
                    <li><a href="/learn/front-end-development-libraries/">Frontend Development Libraries</a></li>
                    <li><a href="/learn/data-visualization/">Data Visualization</a></li>
                    <li><a href="/learn/coding-interview-prep/">Coding Interview Preparation</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="widget useful_links ul_li_block">
                  <h3 className="widget_title mb-50">Legal</h3>
                  <ul className="clearfix">
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms">Terms of Services</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.displayName = 'Footer';
export default Footer;
