import { Grid, Row, Col } from '@freecodecamp/react-bootstrap';
import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { randomQuote } from '../utils/get-words';

import Intro from '../components/Intro';
import Map from '../components/Map';
import { Spacer } from '../components/helpers';
import LearnLayout from '../components/layouts/learn';
import {
  userFetchStateSelector,
  isSignedInSelector,
  userSelector
} from '../redux';
// import Banner from '../components/landing/components/banner';
import Header from '../components/landing/components/header';
// import Feature from '../components/landing/components/feature';
// import Process from '../components/landing/components/process';
// import Partner from '../components/landing/components/partner';
// import Try from '../components/landing/components/try';
// import Certifications from '../components/landing/components/certifications';
import Footer from '../components/landing/components/footer';
import Courses from '../components/landing/components/courses';

interface FetchState {
  pending: boolean;
  complete: boolean;
  errored: boolean;
}

interface User {
  name: string;
  username: string;
  completedChallengeCount: number;
}

const mapStateToProps = createSelector(
  userFetchStateSelector,
  isSignedInSelector,
  userSelector,
  (fetchState: FetchState, isSignedIn: boolean, user: User) => ({
    fetchState,
    isSignedIn,
    user
  })
);

interface Slug {
  slug: string;
}

interface LearnPageProps {
  isSignedIn: boolean;
  fetchState: FetchState;
  state: Record<string, unknown>;
  user: User;
  data: {
    allChallengeNode: any,
    challengeNode: {
      challenge: {
        fields: Slug;
      };
    };
  };
}

function LearnPage({
  isSignedIn,
  fetchState: { pending, complete },
  user: { name = '', completedChallengeCount = 0 },
  data: {
    allChallengeNode,
    challengeNode: {
      challenge: {
        fields: { slug }
      }
    }
  }
}: LearnPageProps) {
  const isBrowser = typeof window !== "undefined"
  if(isBrowser && window.localStorage.afterSignIn) {
    location.href = window.localStorage.afterSignIn;
    window.localStorage.removeItem('afterSignIn');
  }
  const { t } = useTranslation();
  const { quote, author } = randomQuote();
  const courses = allChallengeNode;
  return (
    <>
      <Helmet>
        <title>Extraise</title>
        <link rel="shortcut icon" href="/assets/images/logo/favourite_icon_1.png" />
        <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/icomoon.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/themify-icons.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/fontawesome-all.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.carousel.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.theme.default.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/aos.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/animate.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/splitting.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/magnific-popup.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/_extraise.css" />
        <script defer key="jquery" src="/assets/js/jquery-3.4.1.min.js" />
        <script defer key="popper" src="/assets/js/popper.min.js" />
        <script defer key="bootstrap" src="/assets/js/bootstrap.min.js" />
        <script defer key="owl-carousel" src="/assets/js/owl.carousel.min.js" />
        <script defer key="aos" src="/assets/js/aos.js" />
        <script defer key="splitting" src="/assets/js/splitting.js" />
        <script defer key="magnific-popup" src="/assets/js/magnific-popup.min.js" />
        <script defer key="isotope" src="/assets/js/isotope.pkgd.min.js" />
        <script defer key="masonry" src="/assets/js/masonry.pkgd.min.js" />
        <script defer key="imagesloaded" src="/assets/js/imagesloaded.pkgd.min.js" />
        <script defer key="parallax" src="/assets/js/parallax.min.js" />
        <script defer key="parallax-scroll" src="/assets/js/parallax-scroll.js" />
        <script defer key="gmaps" src="/assets/js/gmaps.js" />
        <script defer key="mCustomScrollbar" src="/assets/js/mCustomScrollbar.js" />
        <script defer key="custom" src="/assets/js/custom.js" />
      </Helmet>
      <Header/>
      <main>
        <section id="banner_section" className="banner_section software_banner deco_wrap d-flex align-items-center text-white clearfix" style={{ minHeight: "400px", backgroundColor: "#fbfbfd", paddingBottom: "220px", paddingTop: "200px" }} >
          <div className="container">
            <div className="row align-items-center justify-content-lg-between">

              <div className="col-lg-7">
                <div className="banner_content c_slide_in">
                  <h1 className="title_text mb-30 text_effect_wrap text_effect_wrap">
                    <span className="text_effect text_effect_left">
                      Welcome {name}
                    </span><br/>
                    <span className="text_effect text_effect_left">
                      to level up your skills !..
                    </span>
                  </h1>
                  <p className="mb-50" data-aos="fade-up" data-aos-delay={200}>
                    {quote} <br />- <small>{author}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="deco_image bg_shape_1" data-background="/assets/images/banner/10_software/bg_1.png" />
          <div className="deco_image bg_shape_2" data-background="/assets/images/banner/10_software/bg_2.png" style={{ backgroundSize : "cover" }} />
          <div className="deco_image bg_shape_3" data-background="/assets/images/banner/10_software/bg_3.png" data-aos="zoom-out" data-aos-delay={100} />
          <div className="deco_image shape_1" data-aos="fade-left" data-aos-delay={500}>
            <img src="/assets/images/banner/10_software/shape_1.png" alt="shape_not_found" />
          </div>
          <div className="deco_image shape_2" data-aos="fade-right" data-aos-delay={700}>
            <img src="/assets/images/banner/10_software/shape_2.png" alt="shape_not_found" />
          </div>
          <div className="deco_image cloud_1" data-aos="fade-up" data-aos-delay={500}>
            <img src="/assets/images/banner/10_software/cloud_1.png" alt="shape_not_found" />
          </div>
          <div className="deco_image cloud_2" data-aos="fade-up" data-aos-delay={700}>
            <img src="/assets/images/banner/10_software/cloud_1.png" alt="shape_not_found" />
          </div>
        </section>
        {/* <Feature /> */}
        {/* <Process /> */}
        {/* <Partner /> */}
        {/* <Try /> */}
        <Courses courses={ courses } />
      </main>
      <Footer />
    </>
  );
}

LearnPage.displayName = 'LearnPage';

export default connect(mapStateToProps, null)(LearnPage);

export const query = graphql`
  query FirstChallenge {
    allChallengeNode {
      group(field: challenge___block, limit: 1) {
        edges {
          node {
            challenge {
              block
              order
              superOrder
              superBlock
            }
          }
        }
        totalCount
      }
    }
    challengeNode(challenge: { order: { eq: 0 }, challengeOrder: { eq: 0 } }) {
      challenge {
        fields {
          slug
        }
      }
    }
  }
`;
