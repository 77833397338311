import React from 'react';
import { useEffect } from 'react';

const Courses = ({ courses }: any): JSX.Element => {

  courses = courses.group.map((course: any) => {
    var _course: any = course.edges[0].node.challenge;
    _course.count = course.totalCount;
    if(_course.block.indexOf("bootstrap") >= 0) {
      _course.image = "/assets/images/icons/bootstrap.png";
    } else if(_course.block.indexOf("euler") >= 0) {
      _course.image = "/assets/images/icons/interview.png";
    } else if(_course.block.indexOf("rosetta") >= 0) {
      _course.image = "/assets/images/icons/interview.png";
    } else if(_course.block.indexOf("algorithm") >= 0) {
      _course.image = "/assets/images/icons/algorithm.png";
    } else if(_course.block.indexOf("algorithm") >= 0) {
      _course.image = "/assets/images/icons/algorithm.png";
    } else if(_course.block.indexOf("jquery") >= 0) {
      _course.image = "/assets/images/icons/jquery.png";
    } else if(_course.block.indexOf("sass") >= 0) {
      _course.block = _course.block.split('sass').join("SASS");
      _course.image = "/assets/images/icons/sass.png";
    } else if(_course.block.indexOf("design") >= 0) {
      _course.image = "/assets/images/icons/css.png";
    } else if(_course.block.indexOf("regular-expression") >= 0) {
      _course.image = "/assets/images/icons/javascript.png";
    } else if(_course.block.indexOf("accessibility") >= 0) {
      _course.image = "/assets/images/icons/html.png";
    } else if(_course.block.indexOf("react-and-redux") >= 0) {
      _course.image = "/assets/images/icons/react-and-redux.png";
    } else if(_course.block.indexOf("redux") >= 0) {
      _course.image = "/assets/images/icons/redux.png";
    } else if(_course.block.indexOf("react") >= 0) {
      _course.image = "/assets/images/icons/react.png";
    } else if(_course.block.indexOf("json") >= 0) {
      _course.block = _course.block.split('json').join("JSON");
      _course.image = "/assets/images/icons/programming.png";
    } else if(_course.block.indexOf("programming") >= 0) {
      _course.image = "/assets/images/icons/programming.png";
    } else if(_course.block.indexOf("debugging") >= 0) {
      _course.image = "/assets/images/icons/programming.png";
    } else if(_course.block.indexOf("es6") >= 0) {
      _course.block = _course.block.split('es6').join("ES6");
      _course.image = "/assets/images/icons/es6.png";
    } else if(_course.block.indexOf("d3") >= 0) {
      _course.block = _course.block.split('d3').join("D3");
      _course.image = "/assets/images/icons/d3.png";
    } else if(_course.block.indexOf("data") >= 0) {
      _course.image = "/assets/images/icons/algorithm.png";
    } else if(_course.block.indexOf("javascript") >= 0) {
      _course.image = "/assets/images/icons/javascript.png";
    } else if(_course.block.indexOf("css") >= 0) {
      _course.block = _course.block.split('css').join("CSS");
      _course.image = "/assets/images/icons/css.png";
    } else if(_course.block.indexOf("html") >= 0) {
      _course.block = _course.block.split('html').join("HTML");
      _course.image = "/assets/images/icons/html.png";
    }
    return _course;
  });
  courses = courses.sort(function (x: any, y: any) {
    // var n = x.superOrder - y.superOrder;
    var n = x.order - y.order;
    if (n !== 0) {
        return n;
    }
    return x.superOrder - y.superOrder;
    // return x.order - y.order;
  })

  var categories: any = {
    "responsive-web-design" : "HTML-CSS",
    "javascript-algorithms-and-data-structures" : "JS",
    "front-end-development-libraries" : "LIBS",
    "data-visualization" : "LIBS",
    "coding-interview-prep" : "INTERVIEW"
  }

  var images: any = {
    "basic-html-and-html5": "/assets/images/icons/html.png",
    "basic-javascript": "/assets/images/icons/javascript.png",
    "bootstrap": "",
    "data-visualization-with-d3": "/assets/images/icons/d3.png",
    // "basic": "/assets/images/icons/javascript.png",
    // "basic": "/assets/images/icons/javascript.png",
    // "basic": "/assets/images/icons/javascript.png",
    // "basic": "/assets/images/icons/javascript.png",
    // "basic": "/assets/images/icons/javascript.png",
    // "basic": "/assets/images/icons/javascript.png",
    // "basic": "/assets/images/icons/javascript.png",
    // "basic": "/assets/images/icons/javascript.png",
    // "basic": "/assets/images/icons/javascript.png"
  }

  useEffect(() => {
    setTimeout(() => {
      var $element_grid = $(".element_grid").isotope({
        itemSelector: ".element-item",
        layoutMode: "fitRows"
      });
      var filterFns: any = {
        numberGreaterThan50: function() {
          var number = $(this)
          .find(".number")
          .text();
          return parseInt(number, 10) > 50;
        },
        ium: function() {
          var name = $(this)
          .find(".name")
          .text();
          return name.match(/ium$/);
        }
      };
      $(".filter-btns-group").on("click", "button", function() {
        var filterValue: any = $(this).attr("data-filter");
        filterValue = filterFns[filterValue] || filterValue;
        $element_grid.isotope({ filter: filterValue });
      });
      $(".button-group").each(function(i, buttonGroup) {
        var $buttonGroup = $(buttonGroup);
        $buttonGroup.on("click", "button", function() {
          $buttonGroup.find(".active").removeClass("active");
          $(this).addClass("active");
        });
      });
    }, 400);
  })

  return (
    <>
      <section id="job_section" className="job_section sec_ptb_30 bg_gray clearfix" style={{ paddingBottom: "120px" }}>
        <div className="container">
          <div className="section_title decrease_size mb-30">
            <h2 className="title_text mb-0">Learn something new!</h2>
          </div>
          <div className="button-group filter-btns-group ul_li mb-30">
            <ul className="clearfix">
              <li><button className="active" data-filter="*">All Courses</button></li>
              <li><button data-filter=".HTML-CSS">HTML-CSS</button></li>
              <li><button data-filter=".JS">Javascript</button></li>
              <li><button data-filter=".LIBS">Framework</button></li>
              <li><button data-filter=".INTERVIEW">Interview Preparation</button></li>
            </ul>
          </div>
          <div className="jobs_grid element_grid">
            {courses.map((course: any, index: any) => {
              if(!categories[course.superBlock]) { return null }
              if(course.block.indexOf("-projects") >= 0) { return null }
              return <div key={index} className= { "element-item " + categories[course.superBlock] } data-category={categories[course.superBlock]}>
                <div>
                  <div className="job_item clearfix" style={{ minHeight: "250px" }}>
                    { course.image && <div className="item_icon" style={{
                          position: "absolute",
                          bottom: "0",
                          border: "none",
                          height: "150px",
                          width: "150px"
                    }}>
                      <img src={course.image} alt="image_not_found" style={{ height: "90px" }} />
                    </div> }
                    <div className="item_content">
                      <h3 className="item_title"><a href={ "/learn/" + course.superBlock + "/" + course.block.toLowerCase() } style={{ textTransform: "capitalize" }}>{ course.block.split("-").join(" ") }</a></h3>
                      <p className="mb-0">{ course.count } Chapters</p>
                    </div>
                    <a href={ "/learn/" + course.superBlock + "/" + course.block.toLowerCase() } className="btn btn_border btn_primary" style={{ bottom: 0, top: "auto", paddingBottom: "12px" }}>Start Now</a>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </section>
    </>
  );
};

Courses.displayName = 'Courses';
export default Courses;
